import { template as template_ad25cefc63bf47469268e558fdc1de06 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ad25cefc63bf47469268e558fdc1de06(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
