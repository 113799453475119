import { template as template_2ea94bfcc1f14804a5de842d221eac06 } from "@ember/template-compiler";
const FKLabel = template_2ea94bfcc1f14804a5de842d221eac06(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
